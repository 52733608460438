import * as z from 'zod';

import { Smartlink } from './smartlink';
import { ResourceDataParser } from '@qualio/search-qri';

export const QuickSearchSuggestionsResponseSchema = z.array(ResourceDataParser).default([]);

export const AnySuggestionParser = z.union([ResourceDataParser, Smartlink]);

export type AnySuggestion = z.infer<typeof AnySuggestionParser>;

export const SuggestionsOption = z.object({
  label: z.string(),
  value: z.union([z.string(), z.number()]).pipe(z.coerce.string()),
});
