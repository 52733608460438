import React from 'react';
import { QAlertBox, QFlex, QAlertTitle, QAlertDescription, QSpacer, QButton } from '@qualio/ui-components';

type ReviewRequestBannerProps = {
  markAsReviewedButtonClick: () => void;
};

export const ReviewRequestBanner: React.FC<ReviewRequestBannerProps> = ({ markAsReviewedButtonClick }) => {
  return (
    <QAlertBox status="warning" alignItems="center">
      <QFlex alignItems="baseline">
        <QAlertTitle>Review request</QAlertTitle>
        <QAlertDescription>Your review is required</QAlertDescription>
        <QSpacer />
        <QButton data-cy="mark-as-reviewed-button" variant="solid" onClick={markAsReviewedButtonClick}>
          Mark as reviewed
        </QButton>
      </QFlex>
    </QAlertBox>
  );
};

export default ReviewRequestBanner;
