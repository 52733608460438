import React, { useCallback, useState, useMemo } from 'react';

import { useRelatedRecordsDrawer } from '@qualio/ui-components';
import { formResolvedQriDisplayText } from '../components/EventFormField/components/QObjectField/selectedItemText';
import { QriDetailsV2, hasResourceSubTypeNameProperty } from '@qualio/search-qri';

export type ReferenceDrawerContextType = {
  handleQResourceLabelClick: (qriDetails: QriDetailsV2) => void;
  qriReferenceDrawerOpen: boolean;
  focusedQri: string | undefined;
  focusedQriDetails: QriDetailsV2 | undefined;
};

export const ReferenceDrawerContext = React.createContext<ReferenceDrawerContextType | null>(null);

export const ReferenceDrawerProvider: React.FC = ({ children }) => {
  const [qriReferenceDrawerOpen, setQriReferenceDrawerOpen] = useState(false);
  const [focusedQri, setFocusedQri] = useState<string>('');
  const [focusedQriDetails, setFocusedQriDetails] = useState<QriDetailsV2 | undefined>(undefined);

  useRelatedRecordsDrawer(
    {
      qriProperties: focusedQriDetails
        ? {
            domain: focusedQriDetails.domain,
            identifiers: focusedQriDetails.identifiers,
            resource: focusedQriDetails.resource,
            urls: {
              ...focusedQriDetails.urls,
              view: focusedQriDetails.urls.view ?? null,
            },
            resourceSubTypeName: hasResourceSubTypeNameProperty(focusedQriDetails)
              ? focusedQriDetails.resourceSubTypeName
              : undefined,
          }
        : undefined,
      qri: focusedQri,
      label: focusedQriDetails ? formResolvedQriDisplayText(focusedQriDetails) : '',
      isLoading: false,
    },
    () => {
      setQriReferenceDrawerOpen(false);
      setFocusedQri('');
      setFocusedQriDetails(undefined);
    },
  );

  const handleQResourceLabelClick = useCallback(
    async (qriDetails: QriDetailsV2) => {
      setFocusedQri(qriDetails.identifiers.qri);
      setFocusedQriDetails(qriDetails);
      setQriReferenceDrawerOpen(true);
    },
    [setQriReferenceDrawerOpen],
  );

  const providerValue = useMemo(() => {
    return { handleQResourceLabelClick, qriReferenceDrawerOpen, focusedQri, focusedQriDetails };
  }, [handleQResourceLabelClick, qriReferenceDrawerOpen, focusedQri, focusedQriDetails]);

  return <ReferenceDrawerContext.Provider value={providerValue}>{children}</ReferenceDrawerContext.Provider>;
};
