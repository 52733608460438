import React from 'react';

import {
  QAsyncMultiSelect,
  QIcon,
  QSelectItem,
  QSelectPlaceholder,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { SuggestionApi } from '../../../types/suggestionApi';
import * as DisplayStrings from '../../../displayStrings';
import { ControllerFieldState } from 'react-hook-form';
import { SuggestionsOption } from '../../../types/suggestion';

type Props = {
  onChange: (selected: QSelectItem[]) => void;
  defaults?: QSelectItem[];
  suggestionApi: SuggestionApi;
  valuePropFromSuggestion: 'uuid' | 'qri' | 'id';
  domain: 'suppliers' | 'documents' | 'events' | 'documents or events';
  debounceInterval?: number;
  omitFromSuggestionResults?: string[] | number[];
  fieldState?: ControllerFieldState;
};

const MultiSelectWithSuggestions: React.FC<Props> = ({
  onChange,
  defaults,
  suggestionApi,
  valuePropFromSuggestion,
  domain,
  debounceInterval,
  omitFromSuggestionResults,
  fieldState,
}) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const waitFor = debounceInterval ?? 500;
  let searched = false;

  const handleLoadOptions = () => {
    let timeout: ReturnType<typeof setTimeout>;
    let resolve: (value: ReturnType<typeof loadSuggestions>) => void;
    let reject: (value: unknown) => void;
    let response: ReturnType<typeof loadSuggestions> = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    const debouncedLoadSuggestions = async (inputValue: string) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        try {
          resolve(loadSuggestions(inputValue));
        } catch (e) {
          showToast({
            title: DisplayStrings.SearchFailed,
            description: DisplayStrings.DefaultErrorMessage,
            status: 'error',
          });
          reject(e);
        }
        response = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
      }, waitFor);
      return response;
    };
    return debouncedLoadSuggestions;
  };

  const omitFromResults = omitFromSuggestionResults?.map((v) => String(v)) ?? [];

  const loadSuggestions = async (inputValue: string) => {
    searched = true;
    if (inputValue.length < 1) {
      return [];
    }
    const data = await suggestionApi.suggest(companyId, inputValue);
    return data
      .filter((item) => {
        let id;
        if (item && 'identifiers' in item) {
          // check if item is a quick search result
          id = item?.identifiers.id;
        } else {
          id = item?.uuid;
        }
        if (!id) {
          return true;
        }
        return !omitFromResults.includes(String(id));
      })
      .map((item) => {
        let label;
        let value;
        if ('identifiers' in item) {
          // check if item is a quick search result
          label = item.displayLabel;
          value = item.identifiers.qri;
          if (valuePropFromSuggestion === 'id') {
            value = item.identifiers.id;
          }
        } else {
          label = item.code ? `${item.code} ${item.title}` : item.title;
          value = item.uuid;
        }
        return SuggestionsOption.parse({
          label: label,
          value: value,
        });
      });
  };

  const noOptionsMessage = () => {
    const text = searched ? 'No results' : `Start typing to search for ${domain}`;
    return <QText>{text}</QText>;
  };

  return (
    <QAsyncMultiSelect
      defaultValue={defaults}
      loadOptions={handleLoadOptions()}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      isInvalid={fieldState?.invalid}
      {...fieldState}
      menuPosition="fixed"
    >
      <QSelectPlaceholder>
        <QIcon iconName="Search" />
        <QText>Search for {domain} to add...</QText>
      </QSelectPlaceholder>
    </QAsyncMultiSelect>
  );
};

export default MultiSelectWithSuggestions;
