import { AxiosInstance } from 'axios';

import { QuickSearchSuggestionsResponseSchema } from '../types/suggestion';
import { SuggestionApi } from '../types/suggestionApi';
import { createRequestConfig, globalSearchApi } from './';

const quickSearchApis: Record<string, SuggestionApi> = {};

class QuickSearchApi implements SuggestionApi {
  private domain: string;
  private searchAdapter: AxiosInstance;

  constructor(domain: string, searchAdapter: AxiosInstance) {
    this.domain = domain;
    this.searchAdapter = searchAdapter;
  }
  async suggest(company: number, search: string) {
    const config = createRequestConfig({});
    const results = await this.searchAdapter.get(
      `/v2/quicksearch?companyId=${company}&term=${search}&resources=${this.domain}`,
      config,
    );
    return QuickSearchSuggestionsResponseSchema.parse(results.data.results);
  }
}

export const quickSearchApiFactory = (domain: 'document' | 'quality-event' | 'supplier') => {
  if (quickSearchApis[domain] === undefined) {
    quickSearchApis[domain] = new QuickSearchApi(domain, globalSearchApi);
  }
  return quickSearchApis[domain]!;
};
