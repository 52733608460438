import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QButton,
  QCenter,
  QCloseButton,
  QIcon,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import eventsApi from '../../api/events.api';
import templatesApi from '../../api/templates.api';
import {
  EventTag,
  EventType,
  ProbabilityType,
  Product,
  QualityEvent,
  QualityEventCreate,
  QualityEventCreateWithTitleRestriction,
  RiskType,
  RootCause,
  SeverityType,
  User,
} from '../../types';
import { EventTemplateField } from '../../types/eventField';
import EventForm from './components/EventForm';
import { DefaultErrorMessage } from '../../displayStrings';

type EventNewModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectsData: {
    eventTypes: EventType[];
    eventTags: EventTag[];
    probabilities: ProbabilityType[];
    severities: SeverityType[];
    risks: RiskType[];
    users: User[];
    products: Product[];
    rootCauses: RootCause[];
  };
  refetchEvents: () => void;
};

const EventNewModal: React.FC<EventNewModalProps> = ({ isOpen, setIsOpen, selectsData, refetchEvents }) => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { mutate: createEventMutate, isLoading: isCreateEventLoading } = useMutation(
    (payload: QualityEventCreate) => eventsApi.create(companyId, payload),
    {
      onSuccess: (data: QualityEvent) => {
        showToast({
          title: 'Event created',
          description: `"${data.title}" was created`,
          status: 'success',
        });
        refetchEvents();
        setIsOpen(false);
      },
      onError: () => {
        showToast({
          title: 'Event not created',
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );
  const [optionalFields, setOptionalFields] = useState<EventTemplateField[]>([
    { attribute_name: 'product', mandatory: false },
    { attribute_name: 'root_cause', mandatory: false },
    { attribute_name: 'risk', mandatory: true },
  ]);

  const formMethods = useForm<QualityEventCreateWithTitleRestriction>({
    mode: 'onSubmit',
    resolver: zodResolver(QualityEventCreateWithTitleRestriction),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
    watch,
  } = formMethods;

  const eventType = watch('workflow_id');

  useEffect(() => {
    if (eventType) {
      const getOptionalFields = async () => {
        const templateDetails = await templatesApi.getEventTemplateDetails(companyId, parseInt(eventType, 10));
        if (!templateDetails) {
          return;
        }
        setOptionalFields(templateDetails.fields);
      };
      getOptionalFields().catch(console.error);
      return;
    }
  }, [eventType, companyId]);

  const formHasErrors = !!Object.keys(errors).length;

  /**
   * Normally against using an effect to watch state, but this is the react hook form official
   * guidance for resetting on submit:
   * https://react-hook-form.com/docs/useform/reset
   */
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (payload: QualityEventCreate) => {
    createEventMutate(payload);
  };

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Create event</QText>
        <QCloseButton onClick={handleClose} data-metrics="create-event-close-modal-button"></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <QStack direction="row" mb={2}>
            <QCenter>
              <QIcon iconName="Info" />
            </QCenter>
            <QCenter>
              <QText fontSize="sm">Provide any information you have about the event. You can add details later.</QText>
            </QCenter>
          </QStack>
          <EventForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            data={selectsData}
            optionalFields={optionalFields}
          ></EventForm>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton
          onClick={handleClose}
          variant="outline"
          data-metrics="create-event-cancel-button"
          data-cy="event-modal-cancel-button"
        >
          Cancel
        </QButton>
        <QButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={formHasErrors}
          isLoading={isCreateEventLoading}
          data-metrics="create-event-button"
          data-cy="create-event-button"
          aria-label="create-event-button"
        >
          Create event
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default EventNewModal;
