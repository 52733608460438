import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { QFormControl, QSelect, QSelectItem } from '@qualio/ui-components';

import { quickSearchApiFactory } from '../../../api/quickSearch.api';
import smartlinksApi from '../../../api/smartlinks.api';
import { QualityEventCreate } from '../../../types';
import MultiSelectWithSuggestions from './MultiSelectWithSuggestions';

type SelectOptionProps = {
  probabilitiesOptions: QSelectItem<string, string>[];
  severitiesOptions: QSelectItem<string, string>[];
  risksOptions: QSelectItem<string, string>[];
  productsOptions: QSelectItem<string, string>[];
  defaultRelated: QSelectItem<string, string>[];
  defaultSupplier: QSelectItem<string, string>[];
  rootCausesOptions: QSelectItem<string, string>[];
};

type Props = {
  fieldName: string;
  required: boolean;
  formMethods: UseFormReturn<QualityEventCreate>;
  selectOptionProps: SelectOptionProps;
};

const EventFormOptionalFields: React.FC<Props> = ({ fieldName, required, formMethods, selectOptionProps }) => {
  const {
    control,
    formState: { errors },
  } = formMethods;
  const {
    productsOptions,
    probabilitiesOptions,
    defaultRelated,
    defaultSupplier,
    risksOptions,
    severitiesOptions,
    rootCausesOptions,
  } = selectOptionProps;
  const requiredFieldHelper = required ? 'This field is required before closing the event.' : undefined;
  const supplierSuggestionApi = quickSearchApiFactory('supplier');

  switch (fieldName) {
    case 'product':
      return (
        <QFormControl
          label="Product"
          error={errors?.product_id?.message}
          isInvalid={!!errors.product_id}
          data-metrics="event-product-select"
          helper={requiredFieldHelper}
        >
          <Controller
            control={control}
            name="product_id"
            render={({ field: { ref: _ref, onChange, ...field } }) => (
              <QSelect
                {...field}
                onChange={(changeObject) => {
                  onChange(changeObject?.value);
                }}
                options={productsOptions}
                isClearable={true}
                menuPosition="fixed"
              />
            )}
          />
        </QFormControl>
      );
    case 'root_cause':
      return (
        <QFormControl
          label="Root cause"
          error={errors.rootcause_id && errors.rootcause_id.message}
          isInvalid={!!errors.rootcause_id}
          data-metrics="event-root-cause-select"
          helper={requiredFieldHelper}
        >
          <Controller
            control={control}
            name="rootcause_id"
            render={({ field: { ref: _ref, onChange, ...field } }) => (
              <QSelect
                {...field}
                onChange={(changeObject) => {
                  onChange(changeObject?.value);
                }}
                options={rootCausesOptions}
                isClearable={true}
                menuPosition="fixed"
              />
            )}
          />
        </QFormControl>
      );
    case 'risk':
      return (
        <>
          <QFormControl
            label="Severity"
            error={errors.severity_id && errors.severity_id.message}
            isInvalid={!!errors.severity_id}
            data-metrics="event-severity-select"
            helper={requiredFieldHelper}
          >
            <Controller
              control={control}
              name="severity_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    onChange(changeObject?.value);
                  }}
                  options={severitiesOptions}
                  isClearable={true}
                  menuPosition="fixed"
                />
              )}
            />
          </QFormControl>
          <QFormControl
            label="Probability"
            error={errors.probability_id && errors.probability_id.message}
            isInvalid={!!errors.probability_id}
            data-metrics="event-probability-select"
            helper={requiredFieldHelper}
          >
            <Controller
              control={control}
              name="probability_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    onChange(changeObject?.value);
                  }}
                  options={probabilitiesOptions}
                  isClearable={true}
                  menuPosition="fixed"
                />
              )}
            />
          </QFormControl>
          <QFormControl label="Risk" data-metrics="event-risk-select" hidden helper={requiredFieldHelper}>
            <Controller
              control={control}
              name="risk_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QSelect
                  {...field}
                  onChange={(changeObject) => {
                    if (changeObject) {
                      onChange(changeObject.value);
                    }
                  }}
                  options={risksOptions}
                  menuPosition="fixed"
                />
              )}
            />
          </QFormControl>
        </>
      );
    case 'related_to':
      return (
        <QFormControl label="Related to" data-metrics="event-related-to-select" helper={requiredFieldHelper}>
          <Controller
            control={control}
            name="related_entity_ids"
            render={({ field: { ref: _ref, onChange, ...field } }) => (
              <MultiSelectWithSuggestions
                {...field}
                valuePropFromSuggestion="uuid"
                suggestionApi={smartlinksApi}
                defaults={defaultRelated}
                domain="documents or events"
                onChange={(
                  items: {
                    value: any;
                  }[],
                ) => onChange(items.map((item: { value: any }) => item.value))}
              />
            )}
          />
        </QFormControl>
      );
    case 'supplier':
      return (
        <QFormControl label="Related suppliers" data-metrics="event-supplier-select" helper={requiredFieldHelper}>
          <Controller
            control={control}
            name="suppliers"
            render={({ field: { ref: _ref, onChange, ...field } }) => (
              <MultiSelectWithSuggestions
                {...field}
                suggestionApi={supplierSuggestionApi}
                defaults={defaultSupplier}
                valuePropFromSuggestion="qri"
                domain="suppliers"
                onChange={(
                  items: {
                    value: any;
                  }[],
                ) => onChange(items.map((item: { value: any }) => item.value))}
              />
            )}
          />
        </QFormControl>
      );
    case 'resulted_from':
      return null;
    default:
      return null;
  }
};

export default EventFormOptionalFields;
