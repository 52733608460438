import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  QBox,
  QDatepicker,
  QMultiSelect,
  QSelect,
  QStack,
  QText,
  QSpacer,
  QSelectPlaceholder,
} from '@qualio/ui-components';
import { EventType } from '../../types/eventType';
import { useSearchParams } from 'react-router-dom';
import { TasksRequest, TaskStatus } from '../../types';
import { User } from '../../types/user';
import { convertUtcStringToDate, convertDateToString } from '../../utils/datetimeUtils';
import { getUrlParameter } from '../../utils/searchParamUtils';
import { useUsersOptions } from '../../hooks/useUsersOptions';

type TaskListFitersProps = {
  setFilterParamsFn: CallableFunction;
  pageSize: number;
  pageNumber: number;
  setTasksPageNumber: (pageNumber: number) => void;
  sortBy: string;
  eventTypes: EventType[] | undefined;
  users: User[] | undefined;
};

const TaskListFiters: React.FC<TaskListFitersProps> = ({
  setFilterParamsFn,
  pageSize,
  pageNumber,
  sortBy,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getTaskStatusesFromUrl = (): Array<TaskStatus> => {
    return searchParams.getAll('status').filter((status) => TaskStatus.safeParse(status).success) as TaskStatus[];
  };

  const [selectedEventType, setSelectedEventType] = useState(getUrlParameter(searchParams, 'event_type'));
  const [selectedStatus, setSelectedStatus] = useState<Array<TaskStatus>>(getTaskStatusesFromUrl());
  const [selectedStartDate, setSelectedStartDate] = useState(
    convertUtcStringToDate(getUrlParameter(searchParams, 'created_at_start_date')),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    convertUtcStringToDate(getUrlParameter(searchParams, 'created_at_end_date')),
  );
  const [showOverdue, setShowOverdue] = useState(getUrlParameter(searchParams, 'is_overdue'));
  const [selectedOwnerId, setSelectedOwnerId] = useState(getUrlParameter(searchParams, 'owner_id'));

  const eventTypes = useMemo(
    () => props.eventTypes?.filter((eventType) => eventType.status === 'effective' && eventType.active) || [],
    [props],
  );
  const users = props.users || [];

  const statusOptions = [
    { value: 'open', label: 'Open' },
    { value: 'closed_fail', label: 'Canceled' },
    { value: 'closed_success', label: 'Completed' },
  ];

  const eventTypesOptions = eventTypes.map((eventType) => {
    return { value: String(eventType.id), label: eventType.name };
  });

  const usersOptions = useUsersOptions(users);

  const createRequestParams = useCallback((): TasksRequest => {
    const emptyValues: Array<any> = ['', [], null, undefined];

    const params: TasksRequest = {
      status: selectedStatus,
      event_type: selectedEventType,
      is_overdue: showOverdue,
      created_at_start_date: convertDateToString(selectedStartDate),
      created_at_end_date: convertDateToString(selectedEndDate),
      page_number: pageNumber + 1,
      page_size: pageSize,
      order_by: sortBy,
      owner_id: selectedOwnerId,
    };
    return Object.fromEntries(Object.entries(params).filter(([_, v]) => emptyValues.includes(v) === false));
  }, [
    selectedStatus,
    selectedEventType,
    selectedStartDate,
    selectedEndDate,
    selectedOwnerId,
    showOverdue,
    pageSize,
    pageNumber,
    sortBy,
  ]);

  useEffect(() => {
    const requestParams = createRequestParams();
    setSearchParams({ ...requestParams, tab: searchParams.get('tab') } as unknown as URLSearchParams);
    setFilterParamsFn(requestParams);
  }, [setFilterParamsFn, createRequestParams, setSearchParams, searchParams]);

  return (
    <QStack>
      <QStack direction="row" spacing={2} align="stretch">
        <QBox w="100%" data-metrics="events-filter-event-type">
          <QSelect
            options={eventTypesOptions}
            onChange={(selected) => {
              props.setTasksPageNumber(0);
              if (!selected) {
                setSelectedEventType('');
                return;
              }
              setSelectedEventType(selected.value);
            }}
            value={selectedEventType}
            aria-label="Event type"
            isClearable={true}
          >
            <QSelectPlaceholder>
              <QText>Event type</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-statuses">
          <QMultiSelect
            options={statusOptions}
            onChange={(selectValues) => {
              props.setTasksPageNumber(0);
              setSelectedStatus(() => selectValues.map((v) => v.value as TaskStatus));
            }}
            value={selectedStatus}
            aria-label="Status"
          >
            <QSelectPlaceholder>
              <QText>Task status</QText>
            </QSelectPlaceholder>
          </QMultiSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-owner">
          <QSelect
            options={usersOptions}
            onChange={(changeObject) => {
              if (!changeObject) {
                setSelectedOwnerId('');
                return;
              }

              props.setTasksPageNumber(0);
              setSelectedOwnerId(changeObject.value);
            }}
            value={selectedOwnerId}
            aria-label="Owners"
            isClearable={true}
          >
            <QSelectPlaceholder>
              <QText>Owner</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
      </QStack>
      <QStack isInline>
        <QText alignSelf="center">Created between</QText>
        <QBox w="10rem" data-metrics="events-filter-start-date">
          <QDatepicker
            placeholder="Start date"
            value={selectedStartDate}
            onChange={(value) => {
              props.setTasksPageNumber(0);
              setSelectedStartDate(value);
            }}
            isClearable={true}
          />
        </QBox>
        <QText alignSelf="center">and</QText>
        <QBox w="10rem" data-metrics="events-filter-end-date">
          <QDatepicker
            placeholder="End date"
            value={selectedEndDate}
            onChange={(value) => {
              props.setTasksPageNumber(0);
              setSelectedEndDate(value);
            }}
            isClearable={true}
          />
        </QBox>
        <QSpacer />
        <QBox w="10rem" data-metrics="events-filter-overdue">
          <QSelect
            options={
              [
                { value: 'true', label: 'Overdue' },
                { value: 'false', label: 'On time' },
              ] as const
            }
            onChange={(changeObject) => {
              if (!changeObject) {
                setShowOverdue('');
                return;
              }

              props.setTasksPageNumber(0);
              setShowOverdue(changeObject.value);
            }}
            value={showOverdue}
            aria-label="Due"
            isClearable={true}
          >
            <QSelectPlaceholder>
              <QText>Due</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
      </QStack>
    </QStack>
  );
};

export default TaskListFiters;
