import React from 'react';
import { QBox, QCenter, QDivider, QDrawer, QSpinner } from '@qualio/ui-components';
import { EventTemplateStepCreateEdit } from '../../../types';
import { useFormTemplateDetails } from '../../../hooks/useFormTemplateDetails';
import TemplateDrawerHeader from './TemplateDrawerHeader';
import EventFormPreviewContainer from './EventFormPreviewContainer';
import { ReferenceDrawerProvider } from '../../../context';

type FormTemplateDrawerProps = {
  companyId: number;
  onClose: any;
  onDelete: (step: EventTemplateStepCreateEdit) => void;
  step: EventTemplateStepCreateEdit;
  preventStepDelete: (step: EventTemplateStepCreateEdit) => boolean;
  isTemplateReadonly: boolean;
};

const FormTemplateDrawer: React.FC<FormTemplateDrawerProps> = ({
  companyId,
  onClose,
  onDelete,
  step,
  preventStepDelete,
  isTemplateReadonly,
}) => {
  const { data, isLoading } = useFormTemplateDetails(
    companyId,
    step.workflow_id as number,
    step.id as number,
    step.form_template_id as number,
  );

  return (
    <ReferenceDrawerProvider>
      <QDrawer
        isOpen={true}
        onClose={onClose}
        size="xl"
        title={
          data && (
            <TemplateDrawerHeader
              isContentTemplate={false}
              template={data}
              onDelete={onDelete}
              step={step}
              preventStepDelete={preventStepDelete}
              isTemplateReadonly={isTemplateReadonly}
            />
          )
        }
      >
        <QDivider />
        <QBox p={1} mt="3">
          {isLoading || !data ? (
            <QCenter pt={10}>
              <QSpinner />
            </QCenter>
          ) : (
            <EventFormPreviewContainer eventFormTemplate={data} isTemplateReadonly={isTemplateReadonly} />
          )}
        </QBox>
      </QDrawer>
    </ReferenceDrawerProvider>
  );
};

export default FormTemplateDrawer;
