import { useContext } from 'react';

import { ReferenceDrawerContext, ReferenceDrawerContextType } from '../context';

export const useReferenceDrawer = (): ReferenceDrawerContextType | null => {
  const context = useContext(ReferenceDrawerContext);

  if (!context) {
    throw new Error('useReferenceDrawer can only be used inside ReferenceDrawerProvider');
  }
  return context;
};
